// third-party
import { configureStore } from '@reduxjs/toolkit'
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux'

import { persistStore } from 'redux-persist'

// project imports
import rootReducer from './reducer'
import persistReducer from 'redux-persist/es/persistReducer'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import localforage from 'localforage'

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistConfig = {
  key: 'root',
  storage: localforage,
  blacklist: ['menu'],
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
}

const pReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: pReducer, //rootReducer
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
})
const persister = persistStore(store)

const { dispatch } = store

const useDispatch = () => useAppDispatch()
const useSelector = useAppSelector

export { store, persister, dispatch, useSelector, useDispatch }
